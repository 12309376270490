import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/the-potential-for-ar-in-professional-settings",
  "title": "The Potential for AR in Professional Settings",
  "excerpt": "Integrating augmented reality in life and what benefits it can bring",
  "tags": ["augmented reality", "tech"],
  "coverPhoto": {
    "cloudinaryAssetData": true,
    "cloudName": "dmocgebcq",
    "publicId": "portfolio/blog-covers/neonbrand-mlZzMow-CQw-unsplash_veutvy.jpg",
    "originalHeight": 3351,
    "originalWidth": 5184,
    "defaultBase64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMMXG/8HwAEwAI0Bj1bnwAAAABJRU5ErkJggg==",
    "defaultTracedSVG": "data:image/svg+xml,%3Csvg%20height%3D%229999%22%20viewBox%3D%220%200%209999%209999%22%20width%3D%229999%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m0%200h9999v9999h-9999z%22%20fill%3D%22%23f9fafb%22%2F%3E%3C%2Fsvg%3E"
  },
  "draft": false,
  "type": "blog",
  "date": "2018-02-28T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I like the idea of VR(virtual reality) and not just for it’s uses for entertainment. I see real interesting usages for AR(augmented reality) technology. I can imagine it being used to make jobs easier and in a sense more centered on humans. It can aid organization and maybe cut down on errors and mistakes in a healthcare setting. Make some jobs faster like building trades, plumbing, and construction.`}</p>
    <h2>{`Improving hospital stays`}</h2>
    <p>{`I can see how using either a glasses AR system or even a tablet based one could improve patient experiences in the hospital. There would be less devices and things that are beeping, these all could be routed to just the medical staff who really need to know. This would make patients feel more comfortable but not sacrifice any of their medical safety to achieve this.`}</p>
    <p>{`Instead of having to drag a blood pressure machine in every room that a nurse goes to maybe they have a wireless cuff that they can track those numbers and others with their tablets. Makes the nurses job faster and less disruptive for everyone.`}</p>
    <p>{`At a glance you can see if you’ve already given someone their meds, they would have a checklist on them or maybe a sign warning you that you’ve already given them meds. It’s simple things that can get lost in a confusing computer system or just overlooked when your a busy human.`}</p>
    <h2>{`Construction advances`}</h2>
    <p>{`In road work you can have markers buried after road work is completed or after burying pipes. These markers could give us information we don’t know about the roadways, their integrity, if they are being breached by the elements, and if they can wait for repairs or they are needed imminently.`}</p>
    <p>{`Before ripping up the roads, construction crews could already have important information they need for laying down, updating, or fixing pipes. The depth they are at, they angles they are dealing with. All without having to rip up the ground to verify this information and then start work. This would cut down on the length of the work and in the end save money.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      